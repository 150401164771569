<template>
    <div class="content">
        <blog-drawer
            v-if="isFinished"
            :title="title"
            :episode-id="episodeId"
            :opus-id="opusId"
            :categories="categories"
            :genres="genres"
            :episodes="episodes"
            :opuses="opuses"
            :authors="authors"
        />
        <v-img
            v-if="illustration"
            class="illustration"
            alt="Bannière"
            :src="illustration"
            :contain="true"
        />
        <h1>{{ title }}</h1>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <v-container class="container-contents" v-html="content"> </v-container>
    </div>
</template>
<script>
import BlogDrawer from '@/components/BlogDrawer';
import axios from 'axios';
import marked from 'marked';
import DOMPurify from 'dompurify';
import _ from 'lodash';
export default {
    name: 'BlogContent',
    components: {
        BlogDrawer,
    },
    data() {
        // const mock = require("../mock/blog-content");
        return {
            isFinished: false,
            illustration: null,
            title: '',
            content: '',
            episodeId: [],
            authorsId: [],
            opusId: null,
            categories: [],
            genres: [],
            episodes: [],
            opuses: [],
            // shortStories: [],
            authors: [],
        };
    },
    watch: {
        async $route(to, from) {
            if (to.params.id !== from.params.id) {
                await this.updateContent();
            }
        },
    },
    created: async function () {
        await this.updateContent();
    },
    methods: {
        async updateContent() {
            this.isFinished = false;
            const resp = await axios.get(
                process.env.VUE_APP_API_URL +
                    `/episodes/${this.$route.params.id}`
            );
            const episode = resp.data;
            this.title = episode.title;
            this.illustration = episode.illustration?.formats.small.url;
            this.content = DOMPurify.sanitize(marked(episode.content));
            this.episodeId = episode.id;
            this.opusId = episode.opus.id;
            // resp = await axios.get(
            //     process.env.VUE_APP_API_URL + `/opuses/${this.opusId}`
            // );
            // const opus = resp.data;
            const opus = episode.opus;
            this.categories = opus.categories;
            this.genres = opus.genres;
            this.episodes = opus.episodes;
            this.authorsId = opus.authors.map((author) => author.id);

            // resp = await axios.get(
            //     process.env.VUE_APP_API_URL + `/authors/${this.authorsId}`
            // );
            // const author = resp.data;
            const authors = episode.opus.authors;
            this.authors = authors;
            this.opuses = _.uniqBy(
                _.flatten(authors.map((author) => author.opuses)),
                'title'
            );
            // this.shortStories = authors.short_stroys;
            // this.opuses.concat(this.shortStories);
            this.authorsId = authors.id;
            this.isFinished = true;
        },
    },
};
</script>

<style lang="scss" scoped>
h1 {
    align-content: center;
}
</style>

<style lang="scss">
.illustration {
    height: 20em;
    width: 100%;
}

.container-contents {
    font-family: $body-font-family;
    font-size: 28px;
    text-align: justify;
    align-content: center;
    display: block;
    width: 80%;
    margin: auto;
}

.container-contents img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.container-contents p {
    margin-bottom: 20px;
}
</style>
