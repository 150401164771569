<template>
    <div>
        <v-navigation-drawer
            app
            clipped
            fixed
            disable-resize-watcher
            permanent
            color="#a5bbd4"
            class="blog-drawer"
        >
            <v-list>
                <v-list-item two-line>
                    <v-list-item-content>
                        <v-list-item-title class="title">
                            <span>{{ title }}</span> <br />
                            de {{ authorsName }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-group
                    active-class="list-item-active-class"
                    no-action
                    sub-group
                    eager
                >
                    <template v-slot:activator>
                        <v-list-item-title>Épisodes</v-list-item-title>
                    </template>

                    <v-list-item
                        v-for="(episode, index) in episodes"
                        :key="index"
                        active-class="list-item-active-class"
                        link
                        :to="`/blogcontent/${episode.id}`"
                        :input-value="title === episode.title"
                        class="inner-drawer"
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="episode.title"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
                <v-list-group
                    active-class="list-item-active-class"
                    no-action
                    sub-group
                    eager
                >
                    <template v-slot:activator>
                        <v-list-item-title v-if="authors.length > 1"
                            >Des mêmes auteurs</v-list-item-title
                        >
                        <v-list-item-title v-else
                            >Du même auteur</v-list-item-title
                        >
                    </template>

                    <v-list-item
                        v-for="(item, index) in opuses"
                        :key="index"
                        active-class="list-item-active-class"
                        link
                        :input-value="opusId === item.id"
                        @click="onClickfilter(item.title, 'search')"
                    >
                        <v-list-item-content>
                            {{ item.title | truncate(20) }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>

                <v-list-group
                    active-class="list-item-active-class"
                    no-action
                    sub-group
                    eager
                >
                    <template v-slot:activator>
                        <v-list-item-title>
                            Dans la même catégorie</v-list-item-title
                        >
                    </template>

                    <v-list-item
                        v-for="(item, index) in categories"
                        :key="index"
                        active-class="list-item-active-class"
                        link
                        @click="onClickfilter(item.name, 'categories')"
                    >
                        <v-list-item-content>
                            <v-list-item-title
                                v-text="item.name"
                            ></v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>

                <v-list-group
                    active-class="list-item-active-class"
                    no-action
                    sub-group
                    eager
                >
                    <template v-slot:activator>
                        <v-list-item-title>
                            Dans le même genre</v-list-item-title
                        >
                    </template>
                    <v-list-item
                        v-for="(item, index) in genres"
                        :key="index"
                        active-class="list-item-active-class"
                        link
                        @click="onClickfilter(item.name, 'genres')"
                    >
                        <v-list-item-content>
                            {{ item.name | truncate(20) }}
                        </v-list-item-content>
                    </v-list-item>
                </v-list-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    name: 'BlogDrawer',
    props: {
        title: {
            type: String,
            required: true,
        },
        authors: {
            type: Array,
            required: true,
        },
        opusId: {
            type: Number,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
        genres: {
            type: Array,
            required: true,
        },
        episodes: {
            type: Array,
            required: true,
        },
        opuses: {
            type: Array,
            required: true,
        },
    },
    computed: {
        authorsName() {
            return this.authors.map((author) => author.nickname).join(', ');
        },
    },
    methods: {
        async onClickfilter(filterValue, queryName) {
            await this.$router.push({
                path: '/productions',
                query: {
                    [queryName]: encodeURI(filterValue),
                },
            });
        },
    },
};
</script>
<style lang="scss">
.title {
    font-size: 1em;
    text-align: center;
}

.title > span {
    text-decoration: underline;
    font-style: italic;
}

.blog-drawer {
    text-align: justify;
}

.inner-drawer {
    text-align: justify;
}
.list-item-active-class {
    color: black !important;
}
</style>
